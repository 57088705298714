import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import InfoHeadForPage from '@/components/InfoHeadForPage/InfoHeadForPage.vue';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';
import { useStore } from 'vuex';
import moment from 'moment';
import SceneBlock from '@/components/Model/PageModelView/components/SceneBlock/SceneBlock.vue';
import appendToastMessage from '@/utils/appendToastMessage';
import FORM_DICTIONARY from '@/dictionaries/validation/dictionary.json';
import { scenesId } from '@/components/Model/PageModelView/components/SceneBlock/constants/scenesId';
import { NotAvailableError } from '@/services/utils/errors/notAvailableError';
import ModelLabel from '@/components/Model/ModelLabel/ModelLabel.vue';
import { getModelValue } from '@/components/Model/ModelCards/utils/getModelValue';
import BasePopOver from '@/components/BaseComponents/BasePopOver/BasePopOver.vue';
import { ModelStatus, RegisteredModelTypes } from '@/types/modelType';
import MoleculeScene from '@/components/Model/PageModelView/components/MoleculeSceneBlock/MoleculeSceneBlock.vue';
import { getTypeNameByModelType } from '@/utils/getNameByModelType';
import ROUTE_NAMES from '@/router/route.names.json';
import { getMkpoClassGrandParent, getMkpoClassParent, getMkpoName } from '@/utils/getMkpoClasses';
import { isAdmin, isExpertWithAccess, isInternalUser, isLeader } from '@/utils/rolesUtils';
import { checkPermissionsForLink } from '@/utils/checkPermissionsForLink';
import { getPdfHistoryOptions, PdfType } from '@/utils/getPdfHistoryOptions';

export default defineComponent({
  name: 'PageModelView',

  components: {
    InfoHeadForPage,
    SceneBlock,
    ModelLabel,
    BasePopOver,
    MoleculeScene
  },

  setup() {
    const {
      params,
      query
    } = useRoute();
    const {
      push,
      resolve
    } = useRouter();
    const {
      dispatch,
      state
    } = useStore();
    const addInfoRef = ref();
    const addTechInfoRef = ref();

    const toggleDropdown = () => {
      return addInfoRef?.value?.BasePopOverRef.toggle(event);
    };

    const toggleTechInfoDropdown = () => {
      return addTechInfoRef?.value?.BasePopOverRef.toggle(event);
    };

    const createdAt = computed(() => {
      return moment(state.models?.modelInfo.createdAt).format('DD.MM.YYYY HH:mm:ss');
    });

    const handleClickRequestLink = (event: MouseEvent) => {
      checkPermissionsForLink(
        event,
        state.users?.user,
        [state.models.modelInfo.registeredModelType.id],
        () => {
          push({
            name: ROUTE_NAMES.PAGE_REQUEST,
            params: { id: state?.models?.modelInfo?.requestId }
          });
        },
        () => {
          const routeData = resolve({
            name: ROUTE_NAMES.PAGE_REQUEST,
            params: { id: state?.models?.modelInfo?.requestId }
          });
          window.open(routeData.href, '_blank');
        },
        () => {
          appendToastMessage(FORM_DICTIONARY.NO_PERMISSIONS);
        }
      );
    };

    const additionalInfo = computed(() => {
      let arr = [
        {
          name: 'Номер заявки',
          text: (state.models?.modelInfo.requestId && getModelValue(state.models?.modelInfo.requestId))
        },
        {
          name: getTypeNameByModelType(state.models.modelInfo?.registeredModelType?.id),
          text: (state.models?.modelInfo.patentId && getModelValue(state.models?.modelInfo.patentId))
        }
      ];
      if (state.models?.modelInfo.registeredModelType?.id === RegisteredModelTypes.INDUSTRIAL_MODEL) {
        arr = arr.concat([
          {
            name: 'Класс МКПО',
            text: (state.models?.modelInfo?.mkpoClassGrandParent?.name && getMkpoClassGrandParent(state.models?.modelInfo))
          },
          {
            name: 'Подкласс МКПО',
            text: (state.models?.modelInfo?.mkpoClassParent?.name && getMkpoClassParent(state.models?.modelInfo))
          },
          {
            name: 'Наименование',
            text: (state.models?.modelInfo?.mkpoClassParent?.name && getMkpoName(state.models?.modelInfo))
          }
        ]);
      }
      return arr;
    });

    const requestInfo = computed(() => getPdfHistoryOptions(PdfType.REQUEST, state.models?.modelInfo?.lastRequestedPdfHistory));
    const regInfo = computed(() => getPdfHistoryOptions(PdfType.REGISTRATION, state.models?.modelInfo?.lastRequestedPdfHistory));

    const addTechInfo = computed(() => {
      return [
        {
          name: 'Дата поступления модели',
          text: `${createdAt.value} МСК`
        },
        {
          name: 'Пользователь, загрузивший модель',
          text: getModelValue(state.models?.modelInfo.author?.login)
        },

        {
          name: 'Дата последнего запрос 3D PDF заявки',
          text: requestInfo.value ? `${moment(regInfo.value?.createdAt)?.format('DD.MM.YYYY HH:mm:ss')} МСК` : '-'
        },
        {
          name: 'Пользователь, запросивший 3D PDF заявки',
          text: requestInfo.value?.requestedBy?.login || '-'
        },

        {
          name: 'Дата последнего запроса 3D PDF регистрации',
          text: regInfo.value ? `${moment(regInfo.value?.createdAt)?.format('DD.MM.YYYY HH:mm:ss')} МСК` : '-'
        },
        {
          name: 'Пользователь, запросивший 3D PDF регистрации',
          text: regInfo.value?.requestedBy?.login || '-'
        }
      ];
    });

    onMounted(async() => {
      try {
        await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.FETCH_MODEL_INFO}`, {
          uuid: params.id,
          partUuid: query.partUuid
        });
      } catch (err) {
        if (err.name === NotAvailableError.name) {
          // appendToastMessage(err.message);
          console.error(err);
        } else {
          appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
        }
      }
      try {
        if (
          state.models.modelInfo?.registeredModelType?.id &&
          (isExpertWithAccess(state.users?.user, [state.models.modelInfo?.registeredModelType?.id]) ||
          isLeader(state.users?.user) ||
          isAdmin(state.users?.user) ||
          isInternalUser(state.users?.user))
        ) {
          await dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.GET_MODEL_SEARCH_HISTORY}`, params.id);
        }
      } catch (err) {
        appendToastMessage(FORM_DICTIONARY.SERVER_ERROR);
      }
    });

    return {
      toggleTechInfoDropdown,
      ROUTE_NAMES,
      addTechInfoRef,
      getTypeNameByModelType,
      RegisteredModelTypes,
      additionalInfo,
      toggleDropdown,
      addInfoRef,
      params,
      createdAt,
      ModelStatus,
      state,
      getModelValue,
      scenesId,
      addTechInfo,
      getMkpoName,
      handleClickRequestLink
    };
  }
});

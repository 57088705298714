import { defineComponent, ref } from 'vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';

export default defineComponent({
  name: 'MoleculeRepresentationBlock',

  components: {
    BaseIconButton
  },

  emits: [
    'clickBallStickRepresentation',
    'clickVanRepresentation',
    'clickCharRepresentation',
    'clickWireframeRepresentation',
    'clickStickRepresentation'
  ],

  setup(_, { emit }) {
    const isVisibleWireframeRep = ref(false);
    const isVisibleStickRep = ref(false);
    const isVisibleBallStickRep = ref(true);
    const isVisibleVanRep = ref(false);
    const isVisibleCharRep = ref(false);

    const clickWireframeRepresentation = () => {
      isVisibleWireframeRep.value = !isVisibleWireframeRep.value;
      emit('clickWireframeRepresentation', isVisibleWireframeRep.value);
    };

    const clickStickRepresentation = () => {
      isVisibleStickRep.value = !isVisibleStickRep.value;
      emit('clickStickRepresentation', isVisibleStickRep.value);
    };

    const clickBallStickRepresentation = () => {
      isVisibleBallStickRep.value = !isVisibleBallStickRep.value;
      emit('clickBallStickRepresentation', isVisibleBallStickRep.value);
    };

    const clickVanRepresentation = () => {
      isVisibleVanRep.value = !isVisibleVanRep.value;
      emit('clickVanRepresentation', isVisibleVanRep.value);
    };

    const clickCharRepresentation = () => {
      isVisibleCharRep.value = !isVisibleCharRep.value;
      emit('clickCharRepresentation', isVisibleCharRep.value);
    };

    return {
      isVisibleWireframeRep,
      isVisibleStickRep,
      isVisibleBallStickRep,
      isVisibleVanRep,
      isVisibleCharRep,
      clickVanRepresentation,
      clickBallStickRepresentation,
      clickCharRepresentation,
      clickWireframeRepresentation,
      clickStickRepresentation
    };
  }
});

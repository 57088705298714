import { defineComponent, PropType } from 'vue';
import BaseIconButton from '@/components/BaseComponents/BaseIconButton/BaseIconButton.vue';
import type { TModelData } from '@/types/modelType';
import { useStore } from 'vuex';
import { MODULES_NAMES } from '@/store/names/modules.name';
import { MODELS_ACTIONS_TYPE } from '@/store/names/action.name';

export default defineComponent({
  name: 'MoleculeActionBlock',

  components: {
    BaseIconButton
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    }
  },

  setup(props) {
    const { dispatch } = useStore();

    const downloadFile = () => {
      dispatch(`${MODULES_NAMES.MODELS}/${MODELS_ACTIONS_TYPE.DOWNLOAD_FILE}`, {
        uuid: props.data.modelUuid,
        fileName: props.data.fileName
      });
    };

    return {
      downloadFile
    };
  }
});

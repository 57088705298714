<template>
  <div class="molecule-action-block">
    <BaseIconButton
      @clicked="downloadFile"
      tool-tip-text="Скачать файл"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/actionIcon/download.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/actionIcon/w-download.svg">
      </template>
    </BaseIconButton>
  </div>
</template>

<script src="./MoleculeActionBlock.ts" lang="ts"/>
<style src="./MoleculeActionBlock.scss" lang="scss"/>

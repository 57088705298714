import { TPdfUnit } from '@/types/modelType';

export enum PdfType {
  REGISTRATION = 'REGISTRATION',
  REQUEST = 'REQUEST'
}

export const getPdfHistoryOptions = (key: PdfType, list: TPdfUnit[]): TPdfUnit | undefined => {
  const pdf = list.find(item => item.pdfType === key);
  if (!list.length) return undefined;
  if (!pdf) throw new Error(`Not found options for key: "${key}"`);
  return pdf;
};

<template>
  <div class="molecule-representation-block">
    <BaseIconButton
      :is-active="isVisibleWireframeRep"
      tool-tip-text="Проволочная модель"
      @clicked="clickWireframeRepresentation"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/representationIcons/wireframe.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/representationIcons/wireframe-w.svg">
      </template>
    </BaseIconButton>
    <BaseIconButton
      :is-active="isVisibleStickRep"
      tool-tip-text="Стержневая модель"
      @clicked="clickStickRepresentation"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/representationIcons/stick.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/representationIcons/stick-w.svg">
      </template>
    </BaseIconButton>
    <BaseIconButton
      :is-active="isVisibleBallStickRep"
      tool-tip-text="Шаростержневая модель"
      @clicked="clickBallStickRepresentation"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/representationIcons/ball&stick.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/representationIcons/ball&stick-w.svg">
      </template>
    </BaseIconButton>
    <BaseIconButton
      :is-active="isVisibleVanRep"
      tool-tip-text="Модель Ван-дер-Ваальса"
      @clicked="clickVanRepresentation"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/representationIcons/van_der_waals.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/representationIcons/van_der_waals-w.svg">
      </template>
    </BaseIconButton>
    <BaseIconButton
      :is-active="isVisibleCharRep"
      tool-tip-text="Названия элементов"
      @clicked="clickCharRepresentation"
    >
      <template v-slot:button-image>
        <img alt="img" src="@/assets/icons/representationIcons/atom_name.svg">
      </template>
      <template v-slot:button-image-active>
        <img alt="img" src="@/assets/icons/representationIcons/atom_name-w.svg">
      </template>
    </BaseIconButton>
  </div>
</template>

<script src="./MoleculeRepresentationBlock.ts" lang="ts"/>
<style src="./MoleculeRepresentationBlock.scss" lang="scss"/>

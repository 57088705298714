<template>
  <BaseLoader v-if="isLoading || isProcessing">
    <template v-slot:loader-content v-if="isProcessing">
      <div class="ui-title-large">Модель в обработке</div>
    </template>
  </BaseLoader>
  <div class="scene-wrapper molecule-wrapper">
    <MoleculeRepresentationBlock
      @clickWireframeRepresentation="toggleWireframeRep($event)"
      @clickStickRepresentation="toggleStickRep($event)"
      @clickBallStickRepresentation="toggleBallStickRep($event)"
      @clickVanRepresentation="toggleVanRep($event)"
      @clickCharRepresentation="toggleCharRep($event)"
    />
    <MoleculeActionBlock :data="data" />
    <div class="scene" :id="moleculeSceneId"></div>
  </div>
</template>

<script src="./MoleculeSceneBlock.ts" lang="ts"/>
<style src="./MoleculeSceneBlock.scss" lang="scss"/>

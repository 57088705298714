<template>
  <InfoHeadForPage
    page-name="Модель"
    :has-bottom-block="false"
  >
    <template v-slot:content-top>
      <div class="head-info-content view-head">
        <div class="p-d-flex p-ai-end">
          <div class="label-wrapper">
            <ModelLabel :model-type-id="state.models.modelInfo?.registeredModelType?.id" />
            <div
              v-if="state.models.modelInfo.variantId !== null"
              class="variant-label"
            >
              {{ state.models.modelInfo.variantId }}
            </div>
          </div>
          <div
            class="head-small-text request-block"
            v-if="!!state.models.modelInfo.requestId"
          >
            <span class="info-type">Номер заявки</span>
            <span class="info-value">
              <router-link
                :to="{ name: ROUTE_NAMES.PAGE_REQUEST, params: { id: state?.models?.modelInfo?.requestId }}"
                @click="handleClickRequestLink($event)"
                target="_blank"
                class="info-link"
              >
                {{ getModelValue(state.models.modelInfo.requestId) }}
              </router-link>
            </span>
          </div>
          <div
            class="head-small-text patent-block"
            v-if="!!state.models.modelInfo.patentId"
          >
            <span class="info-type">{{ getTypeNameByModelType(state.models.modelInfo?.registeredModelType?.id) }}</span>
            <span class="info-value">{{ getModelValue(state.models.modelInfo.patentId) }}</span>
          </div>
          <div
            class="head-small-text patent-block"
            v-if="!!state.models?.modelInfo.mkpoClass"
          >
            <span class="info-type">Наименование</span>
            <span class="info-value">{{ getMkpoName(state.models?.modelInfo) }}</span>
          </div>
        </div>
        <div class="additional-info-button-wrapper">
          <div
            id="additional-info-tech"
            class="p-d-flex p-ai-end additional-info tech-info"
            @click="toggleTechInfoDropdown"
          >
            <img class="tech-info-icon" alt="img" src="@/assets/icons/tech_info.svg" />
            <span>Техническая информация</span>
          </div>
          <div
            id="additional-info"
            class="p-d-flex p-ai-end additional-info"
            @click="toggleDropdown"
          >
            <i class="pi pi-info-circle" ></i>
            <span>О заявке</span>
          </div>
        </div>
        <BasePopOver
          append-element-id="#additional-info"
          className="add-info-model"
          ref="addInfoRef"
        >
          <template v-slot:popover-content>
            <template
              v-for="(item, index) in additionalInfo"
              :key="index"
            >
              <div
                v-if="!!item.text"
                class="info-line"
              >
                <span class="name">{{ item.name }}</span>
                <span class="text">{{ item.text }}</span>
              </div>
            </template>
          </template>
        </BasePopOver>
        <BasePopOver
          append-element-id="#additional-info-tech"
          className="add-info-model"
          ref="addTechInfoRef"
        >
          <template v-slot:popover-content>
            <template
              v-for="(item, index) in addTechInfo"
              :key="index"
            >
              <div
                v-if="!!item.text"
                class="info-line"
              >
                <span class="name">{{ item.name }}</span>
                <span class="text">{{ item.text }}</span>
              </div>
            </template>
          </template>
        </BasePopOver>
      </div>
    </template>
  </InfoHeadForPage>
  <MoleculeScene
    :data="state.models.modelInfo"
    :is-processing="state.models.modelInfo.status === ModelStatus.PROCESSING"
    v-if="state?.models?.modelInfo?.registeredModelType?.id === RegisteredModelTypes.PARTICLE"
  />
  <SceneBlock
    v-else
    :data="state.models.modelInfo"
    :scenes-ids="scenesId"
    :is-processing="state.models.modelInfo.status === ModelStatus.PROCESSING"
    :is-loading="state.models.isLoadingModel"
  />
</template>

<script src="./PageModelView.ts" lang="ts"/>
<style src="./PageModelView.scss" lang="scss" />

import { defineComponent, onMounted, PropType, ref } from 'vue';
import BaseLoader from '@/components/BaseComponents/BaseLoader/BaseLoader.vue';
import { moleculeSceneId } from '@/components/Model/PageModelView/components/MoleculeSceneBlock/utils/constants';
import type { TModelData } from '@/types/modelType';
import { FULL_PATH } from '@/services/apiService';
import DICTIONARY from '@/dictionaries/validation/dictionary.json';
import MoleculeActionBlock
  from '@/components/Model/PageModelView/components/MoleculeSceneBlock/components/MoleculeActionBlock/MoleculeActionBlock.vue';
import MoleculeRepresentationBlock
  from '@/components/Model/PageModelView/components/MoleculeSceneBlock/components/MoleculeRepresentationBlock/MoleculeRepresentationBlock.vue';
import appendToastMessage from '@/utils/appendToastMessage';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NGL = require('./utils/ngl');

export default defineComponent({
  name: 'MoleculeSceneBlock',

  components: {
    BaseLoader,
    MoleculeActionBlock,
    MoleculeRepresentationBlock
  },

  props: {
    data: {
      type: Object as PropType<TModelData>,
      required: true
    },
    isProcessing: {
      type: Boolean
    }
  },

  setup(props) {
    const isLoading = ref(true);
    let structure: any;
    let ballStickRep: any;
    let vanRep: any;
    let stickRep: any;
    let wireframeRep: any;
    let charRep: any;

    const toggleBallStickRep = (bool: boolean) => {
      if (bool) {
        ballStickRep = structure.addRepresentation('ball+stick', {
          multipleBond: true,
          compressed: false,
          lazy: true,
          sphereDetail: Number.MAX_SAFE_INTEGER,
          radialSegments: Number.MAX_SAFE_INTEGER,
          flatShaded: false,
          depthWrite: true
        });
      } else {
        structure.removeRepresentation(ballStickRep);
      }
    };

    const toggleVanRep = (bool: boolean) => {
      if (bool) {
        vanRep = structure.addRepresentation('ball+stick', {
          sphereDetail: 3,
          radiusSegments: 25,
          aspectRatio: 10
        });
      } else {
        structure.removeRepresentation(vanRep);
      }
    };

    const toggleStickRep = (bool: boolean) => {
      if (bool) {
        stickRep = structure.addRepresentation('hyperball');
      } else {
        structure.removeRepresentation(stickRep);
      }
    };

    const toggleWireframeRep = (bool: boolean) => {
      if (bool) {
        wireframeRep = structure.addRepresentation('line', {
          multipleBond: true
        });
      } else {
        structure.removeRepresentation(wireframeRep);
      }
    };

    const toggleCharRep = (bool: boolean) => {
      if (bool) {
        charRep = structure.addRepresentation('label', { labelType: 'element' });
      } else {
        structure.removeRepresentation(charRep);
      }
    };

    onMounted(async() => {
      if (!props.isProcessing) {
        try {
          isLoading.value = true;
          const stage = new NGL.Stage(moleculeSceneId, {
            backgroundColor: '#e8e8e8',
            clipFar: 1000,
            clipNear: 0,
            clipDist: 0,
            cameraFov: 15,
            ambientIntensity: 0,
            rotateSpeed: 0.5,
            probeRadius: 9999999999,
            smooth: Number.MAX_SAFE_INTEGER,
            tooltip: false
          });
          stage.mouseControls.remove('clickPick-left');
          stage.mouseControls.remove('clickPick-right');
          stage.mouseControls.remove('drag-right');
          window.addEventListener('resize', function() {
            stage.handleResize();
          }, { passive: false });
          const jwt = localStorage.getItem('JWT');
          structure = await stage.loadFile(
            `${FULL_PATH}/model/uuid/${props.data.modelUuid}/mol?authorization=Bearer ${jwt}`,
            { ext: 'mol' }
          );
          toggleBallStickRep(true);
          structure.autoView();
          isLoading.value = false;
        } catch (e) {
          appendToastMessage(DICTIONARY.SERVER_ERROR);
        }
      }
    });

    return {
      toggleStickRep,
      toggleWireframeRep,
      toggleCharRep,
      toggleVanRep,
      toggleBallStickRep,
      moleculeSceneId,
      isLoading
    };
  }
});
